import React from 'react';
import ReactDOM from 'react-dom';

import {Table} from 'antd';
import 'antd/lib/table/style/css';
import {Tag} from 'antd';
import 'antd/lib/tag/style/css';
import {Icon} from 'antd';
import 'antd/lib/icon/style/css';
import {Tooltip} from 'antd';
import 'antd/lib/tooltip/style/css';
import {Spin} from 'antd';
import 'antd/lib/spin/style/css';
import {Popover} from 'antd';
import 'antd/lib/popover/style/css';
import {Input} from 'antd';
import 'antd/lib/input/style/css';


import {services} from './services';
import {stages} from './stages';


  
const columns = [{
    title: 'Service Name',
    dataIndex: 'name',
    key: 'name',
  }, {
    title: (
      <span>
        {'Development'} 
        <Tooltip title="Kubernetes Dashboard">
          <Icon type="setting" style={{ marginLeft: '16px', cursor: 'pointer' }} 
              onClick={() => window.open('https://dashboard.development.languages.akelius.com/#!/overview?namespace=business-school-development', "_blank")} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'development',
    key: 'age'
  }, {
    title: (
      <span>
        {'Testing'} 
        <Tooltip title="Kubernetes Dashboard">
          <Icon type="setting" style={{ marginLeft: '16px', cursor: 'pointer' }} 
              onClick={() => window.open('https://dashboard.development.languages.akelius.com/#!/overview?namespace=business-school-testing', "_blank")} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'testing',
    key: 'testing',
  }, {
    title: (
      <span>
        {'Staging'} 
        <Tooltip title="Kubernetes Dashboard">
          <Icon type="setting" style={{ marginLeft: '16px', cursor: 'pointer' }} 
              onClick={() => window.open('https://dashboard.production.languages.akelius.com/#!/overview?namespace=business-school-staging', "_blank")} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'staging',
    key: 'staging'
  }, {
    title: (
      <span>
        {'Production'} 
        <Tooltip title="Kubernetes Dashboard">
          <Icon type="setting" style={{ marginLeft: '16px', cursor: 'pointer' }} 
              onClick={() => window.open('https://dashboard.production.languages.akelius.com/#!/overview?namespace=business-school-production', "_blank")} />
        </Tooltip>
      </span>
    ),
    dataIndex: 'production',
    key: 'production'
  }
];

const renderStatus = (service, idx) => ({
  key: `service_${idx}`,
  name: <h3 style={{ marginBottom: 0}}>{service.name}</h3>,
  development: <Status status={service.development.status} link={service.link} git={service.git} url={service.development.url} swagger={service.swagger} report={service.report} commit={service.development.commit} timestamp={service.development.timestamp} loading={service.development.loading} /> ,
  testing: <Status status={service.testing.status} link={service.link} git={service.git} url={service.testing.url} swagger={service.swagger} report={service.report} commit={service.testing.commit} timestamp={service.testing.timestamp} loading={service.testing.loading} /> ,
  staging: <Status status={service.staging.status} link={service.link} git={service.git} url={service.staging.url} swagger={service.swagger} report={service.report} commit={service.staging.commit} timestamp={service.staging.timestamp} loading={service.staging.loading} /> ,
  production: <Status status={service.production.status} link={service.link} git={service.git} url={service.production.url} swagger={service.swagger} report={service.report} commit={service.production.commit} timestamp={service.production.timestamp} loading={service.production.loading} /> ,
});

const templateStatus = {
  status: 'unknown',
  commit: null,
  timestamp: null,
  loading: false
};

const status2Color = {
  'unknown': '#ccc',
  'up': 'green',
  'down': 'red'
};

class StatusTable extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      services : services.map((service, idx) => ({
        name: service.name,
        link: service.link,
        git: service.git,
        swagger: service.swagger,
        report: service.report,
        development: Object.assign({url: service.development}, templateStatus),
        testing: Object.assign({url: service.testing}, templateStatus),
        staging: Object.assign({url: service.staging}, templateStatus),
        production: Object.assign({url: service.production}, templateStatus)
      }))
    };
    this.getStatus = (service, idx) => {
      Object.keys(service).filter(key => stages.includes(key)).forEach(key => {
        const services = [...this.state.services];
        services[idx] = Object.assign(services[idx], { [key] : Object.assign(services[idx][key], { loading: true }) });
        this.setState({services: services});
        if (service.info) {
          fetch(service[key] + service.info + '?v=' + Date.now(), {
            headers: {
              'Content-Type': 'application/json; charset=utf-8'
            }
          }).then((res) => {
            res.json().then(json => {
              if (json.git) {
                const services = [...this.state.services];
                services[idx] = Object.assign(services[idx], {
                  [key]: {
                    url: services[idx][key].url,
                    status: 'up',
                    commit: json.git.commit.id,
                    timestamp: json.git.commit.time,
                    loading: false
                  }
                });
                this.setState({services: services});
              }
            })
          }, (res) => {
            const services = [...this.state.services];
            services[idx] = Object.assign(services[idx], {
              [key]: {
                status: 'down',
                commit: null,
                timestamp: null,
                loading: false
              }
            });
            this.setState({services: services});
          });
        } else {
          services[idx] = Object.assign(services[idx], {
            [key]: {
              url: services[idx][key].url,
              status: 'up',
              commit: 'none',
              timestamp: Date.now(),
              loading: false
            }
          });
        }
      })
    };
  }

  componentDidMount() {
    services.forEach((service, idx) => {
      this.getStatus(service, idx);
      setInterval(() => {
        this.getStatus(service, idx) } , 15000);
    });
  }

  render() {
      return (
          <Table dataSource={this.state.services.map(renderStatus)} columns={columns} pagination={false}/>
      );
  }
}

class Status extends React.Component {

  state = {
    linkPopoverVisible: false
  }

  openPopover = () => {
    this.setState({
      linkPopoverVisible: true
    });
  }

  closePopover = (linkPopoverVisible) => {
    this.setState({linkPopoverVisible});
  }

  urlRef = React.createRef();

  copyToClipboard = () => {
    this.urlRef.current.input.select();
    document.execCommand('copy');
  }

  getContent = (url) => {
    return <Input ref={this.urlRef} style={{ width: 600 }} size="large" addonAfter={<Icon type="copy" onClick={this.copyToClipboard} />} value={url} readOnly={true} />
  }

  header = ( <h3 style={{ marginTop: '0.5em' }}>{'Copy URL'}</h3> )

  render() {

    return (
      <div style={{ display: 'flex', justifyContent: 'flex-start', minWidth: '20ex' }}>
        <div style={{ minWidth: '9ex' }}>
          <Tag color={status2Color[this.props.status]} onClick={() => this.props.commit ? window.open(this.props.git + this.props.commit, "_blank") : null } >
            {this.props.commit || 'unknown'}
          </Tag>
        </div>
        { this.props.timestamp && <Tooltip title={this.props.timestamp}><Icon type="clock-circle-o" /></Tooltip> }

        { (this.props.swagger && this.props.status === 'up') ?
          <Tooltip title="Swagger"><Icon style={{ marginLeft: '16px', cursor: 'pointer' }} type="api"
                onClick={() => this.props.swagger ? window.open(this.props.url + this.props.swagger, "_blank") : null } />
          </Tooltip> :
          null
        }
        { (this.props.report) ?
            <Tooltip title="Report">
              <Icon style={{ marginLeft: '16px', cursor: 'pointer' }} type="area-chart"
                     onClick={() => this.props.report ? window.open(this.props.report, "_blank") : null } />
            </Tooltip> :
            null
        }
        { (this.props.url) ?
            <div> <Icon style={{ marginLeft: '16px', cursor: 'pointer' }} type="export"
                        onClick={this.openPopover} />
              <Popover visible={this.state.linkPopoverVisible} trigger="click" title={this.header} content={this.getContent(this.props.url)} onVisibleChange={this.closePopover} /></div> :
            null
        }

        { (this.props.link &&  this.props.url) ?
            <a href={this.props.url} target="_blank" style={{ marginLeft: '16px', cursor: 'pointer' }}><Icon type="link" /></a> :
            null
        }
        <span style={{ marginLeft: '16px', width: '20px' }}>
                  { this.props.loading && <Spin /> }
        </span>
      </div>
    );
  }
}

export {StatusTable};
