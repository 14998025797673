import React from 'react';
import ReactDOM from 'react-dom';

import {Layout} from 'antd';
import 'antd/lib/layout/style/css';
import {StatusTable} from './status';

const {Header, Content, Footer} = Layout;

const App = () => (
  <div className="App" style={{minHeight: '100%'}}>
    <Layout className="layout" style={{minHeight: '100%'}}>
        <Header style={{ background: '#fff', boxShadow: '0 2px 8px #f0f1f2', zIndex: 10}}>
            <h1 style={{ color: '#002d60' }}>Business School Service Monitor</h1>
        </Header>
        <Content style={{ padding: '0 50px'}}>
            <div style={{ background: '#fff', padding: 24 }}>
                <StatusTable />
            </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
            ©2018
        </Footer>
    </Layout>
  </div>
);

ReactDOM.render(<App />, document.getElementById('root'));

// Hot Module Replacement
if (module.hot) {
  module.hot.accept();
}